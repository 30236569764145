import * as React from "react"
import {msgStyles} from "./imp-message.module.css"
import {Timer} from "./timer"

export function ImpMessage() {

    const timeLeft = <Timer />
    const msg = "Running into issues ordering online? you can also use our online ordering form. Thank you for your understanding. "

    return (
        <div className={msgStyles}>
            <div className="container">
                <p><strong>{msg}</strong> - <a href="https://www.ifi-test.com/shop/" rel="noopener nofollow noreferrer">👉 Online Ordering Form</a></p>
            </div>
        </div>
    )
}
